.gallery-wrap, .thumbnails-wrap{
  display: flex;
  flex-flow: row wrap;
}
.gallery-container{
  flex: 0 0 100%;
  margin-top: 2rem;
  padding: 0 1%;
}
.gallery-content{
  flex: 0 0 30%;
  order:1;
}

.my-gallery {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  max-width: 80rem;
  //margin: 0 auto;
  img {
    width: 100%;
    height: auto;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    &:hover{
      box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }
  }
  figure {
    display: block;
    //float: left;
    margin: 1rem 0;
    flex: 0 0 19%;
    &.figure--main{
      flex: 0 0 100%;
    }
    figcaption {
      display: none;
    }
    a{
      display: block;
      height: 100%;
    }
  }
}

.thumbnails{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  min-height: 50px;
  width: 100%;
  margin: 1rem 0;

  &__half{
    flex: 0 0 100%;
    //border: 1px solid magenta;
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 2rem;
    &:hover{
      cursor: pointer;
    }
  }
  //thumbnail title goes inside thumbnail half
  &__title{
    flex: 0 0 100%;
    background-color: $colorBrand;
    color: white;
    font-size: 2rem;
    padding: 1rem;
  }

}
.thumbnail{
  position: relative;
  flex: 0 0 33.333%;
  //border: 1px solid white;
  background: no-repeat center;
  background-size: cover;
  hr{
    display: none;
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }
  &__content{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:before{
    display: block;
    content:'';
    padding-bottom: 65%;
  }
  &.more{
    background-color: $colorBrand;
    color: white;
    &:hover{
      i{
        transform: scale(1.3);
      }
    }
    i{
      color: white;
      font-size: 4rem;
      opacity: 0.8;
      transition: all 0.3s;
    }

  }
}

@media screen and(min-width: 440px){
  .thumbnail{
    &.more{
      i{
        font-size: 6rem;
      }
    }
  }

}
@media screen and(min-width: 680px){
  .thumbnails{
    &__half{
      flex: 0 0 49%;
    }
  }
  .thumbnail{
    &.more{
      i{
        font-size: 6rem;
      }
    }
  }
}
@media screen and(min-width: 875px){
  .gallery-container{
    flex: 0 0 50%;
  }
}