@import '../1-tools/mixins';
.top-header{
  background-color: $colorBrand;
  color: white;
  text-align: center;
  a, i{
    color: white;
  }
  .container{
    @extend #wrapper;
  }
  &__block{
    display: inline-block;
    //margin: 1rem 1rem 1rem 0;
    margin: 0.8rem 1rem 0.8rem 0;
    &.email{
      display: none;
    }

    a{
      display: flex;
      align-items: center;

      i{
        margin-right: 1rem;
        order: 2;
        font-size: 3rem;
      }
      span{
        font-size: 2rem;
        font-weight: bold;
        letter-spacing: 2px;
      }
    }
  }
}

header{
  display: flex;
  flex-flow: row wrap;
  padding: 1rem 0;
  vertical-align: baseline;
  align-items: center;
  min-height: 8rem;
  position: relative;
  @include clearfix();
  p{
    margin: 0;
  }
}


.logo{
  display: none;
  margin: 1rem auto;
  width: 20rem;
  height: auto;
  background: no-repeat center;
  //border: 1px solid $colorBrand;
  text-align: center;
  min-height: 6rem;
  &.active{
    display: block;
  }
}


.header{

  flex: 1 0;
  order: 0;
  position: absolute;
  right: 0;
  min-height: 6rem;
  width: calc(100% - 4.5rem);


  &__info{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    min-height: 6rem;
    flex-flow: row wrap;

  }
  &__block{
    display: block;
    align-items: center;
    font-size: 1.4rem;
    text-align: center;
    order: 1;

    a{
      display: block;
    }
    p{
      strong{
        display: block;
      }
    }
    i{
      font-size:3rem;
    }
  }
  a.header__block{
    font-weight: bold;
    font-size: 1.3rem;
    color: $colorBrand;
    margin-right: 2rem;
    &:hover{
      color: lighten($colorBrand,15);
    }
  }
}

@media screen and(min-width: 550px){

  .top-header{
    &__block{
      span{
        font-size: 2rem;
      }
    }
  }
  .header{
    flex: 1 0;
    order: 0;
    position: absolute;
    right: 0;
    min-height: 6rem;
  }

}

@media screen and (min-width: 680px){
  header{
    flex: 1 0;
    display: flex;
    flex-flow: row wrap;
    position: relative;
    padding: 1rem 0 0 0;


    .logo{
      display: block;
      flex: 0 0 25rem;
      //margin: 0;
      min-height: 10rem;
      position: relative;
      z-index: 2;
    }

  }
  .top-header{
    &__block{
      &.email{
        display: inline-block;
      }
    }
  }
  .header{
    flex: 1 0;
    position: static;
    width: auto;
    &__info{
      flex-flow: row nowrap;
      justify-content: flex-end;
    }
    &__block{
      margin-left: 1rem;
      display: block;
      &:nth-child(2), &:first-of-type{
        display: block;
      }
      a{
        display: inline-block;
      }
    }
  }
}


@media screen and (min-width: 875px){
  header{
    margin-top: 2rem;
    .logo{
      flex: 0 0 25rem;
      //margin: 1rem;
    }
  }
  .header{
    &__block{
      display: flex;
      margin-left: 4rem;
      &:nth-child(2), &:first-of-type{
        display: flex;
      }
    }
  }
}

@media screen and (min-width: 1024px){
  header{
    align-items: stretch;
  }
}
@media screen and (min-width: 1366px){
  header{
    align-items: stretch;
  }
  .header{
    a.header__block{
      margin-right: 0.5rem;
    }
  }
}