//
// Some Useful color Vars via http://clrs.cc/
//

// Cool
$aqua:  #7FDBFF;
$blue:  #0074D9;
$navy:  #001F3F;
$teal:  #39CCCC;
$green: #2ECC40;
$olive: #3D9970;
$lime:  #01FF70;


// Warm
$yellow:  rgba(255,213,29,1);
$orange:  #FF851B;
$red:     #FF4136;
$fuchsia: #F012BE;
$purple:  #B10DC9;
$maroon:  #85144B;


// Gray Scale

$color1: rgba(239,239,239,1);
$color2: rgba(213,213,213,1);

$color3: rgba(0,0,0,0.7);

$color4: rgba(227,227,227,1);

$color5: rgba(255,213,29,1);



$white:  #fff;
$silverlight: #F5F5F5;
$silver: #EFEFEF;//rgb(239,239,239)
$silverdark: #E3E3E3;//rgb(227,227,227)

$graylight: #D5D5D5;//rgb(213,213,213)
$grayash: #6c6c6c;//rgb(108,108,108)
$gray:   #585858;//rgb(88,88,88)
$black:  #000; //

$price: rgba(0,0,0,0.7);
//
// Add whatever variables you would like made available to the whole app
//


$colorBrand: #880e4f;
//$colorBrand: #c9aa80;
