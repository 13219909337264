// This is the single file output by sass. It is intended to ONLY @import other files.

// 1 - TOOLS
//@import '1-tools/bourbon/bourbon'

@import '1-tools/legacy';

@import '1-tools/normalize';
@import '1-tools/colours';
@import '1-tools/icomoon';
@import '1-tools/photoswipe/core';
@import '1-tools/photoswipe/default-skin';
@import '1-tools/photoswipe/photoswipe';

// Variables for starter kit
@import 'variables';

// 2 - BASICS
@import '2-basics/body-element';
//@import '2-basics/selection-colors';
@import '2-basics/links';
@import '2-basics/icons';
@import '2-basics/typography';
@import '2-basics/forms';
@import '2-basics/buttons';



// 3 - Modules
//@import '3-modules/cart';
@import '3-modules/components';
@import '3-modules/layout';
@import '3-modules/header';
@import '3-modules/nav-main';
@import '3-modules/main';
//@import '3-modules/products-preview';
//@import '3-modules/products-list';
//@import '3-modules/product-modal';
//@import '3-modules/product';
@import '3-modules/footer';
// 4 - pages

//
@import '2-basics/helpers';//Imported here so it can overwrite css classes






