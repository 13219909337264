@import "../1-tools/colours";

[class^="icon-"], [class*=" icon-"] {
  display: inline-block;
  font-size: 40px;
  color: $colorBrand;

  .header__block & {
    margin-right: 1rem;
  }
  .header__block & {
    //font-size: 22px;
    //background-color: $colorBrand;
    //color: #fff;
  }
  hr{display:none;}
}