

.product__order{
  //display:flex;
  //flex-flow: row wrap;
  //justify-content: flex-start;
  //align-items: baseline;
  padding: 10px;
  & > div{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    //justify-content: space-between;
  }
  p{
    flex: 100%;
    margin-bottom: 0.5rem;
    padding: 0;
  }
  small{
    display: block;
    margin: 20px 0 20px;
    font-size: 1.1rem;
    line-height:1.6rem;
    &::before{
      content:"*";
    }
  }
  input{
    width: 100px;
  }
  .buyproduct{
    width: 230px;
    padding: 15px;
  }
}

input{
  display:inline-block;
  padding:5px;
  max-width: 100%;
  background-color: $color5;
  font: bold 29px/29px $fontSecondary;
  border:none;
  margin-right: 10px;
  outline: none;
  border-radius: 0;
  appearance: none !important;
  &[disabled]{
    opacity: 1;
  }

}
textarea,
input.text,
input[type="text"],
input[type="button"],
input[type="submit"],
.input-checkbox {
  -webkit-appearance: none;
  border-radius: 0;
}


label{
  display: inline-block;
  position:relative;
  margin-bottom: 20px;
  appearance: none !important;
  span{
    position:absolute;
    left: 0;
    top: 52px;
    font-size: 11px;
  }
}

label[for='quantity'], label[for='area'] {
  display: flex;
  align-items: center;
  input{
    flex: 0 0 50%;
  }
}
label[for='thickness'], label[for='area']{
  input{
    margin-right: 0;
  }
}
label[for='thickness']{
  input{
    width: 60px;
    text-align: right;
  }
}
input[type='submit']{
  display:block;
  //padding:1.5rem;
  flex: 1 1 100%;

}

//Helper classes

//.separator{
//  display:block;
//  width: 1px;
//  height: 5rem;
//  margin: 0 15px;
//  background-color:  $color5;
//}

.volume{
  padding-left: 0.5rem;
  font-size: 1.1rem;
  font-weight: bold;
}

.multiplier{
  flex: 0 0 15px;
  display: inline-block;
  text-align: center;
  margin: 0 10px;
  font-family: "Open Sans Condensed";
  font-size: 29px;
  font-weight: bold;
  line-height: 50px;
  &::before{
    content:"X";
  }

}

@media screen and (min-width: 875px){

  label[for='quantity']{
    flex: 0 0 150px;
  }
  label[for='area']{
    flex: 0 0 100px;
  }
  label[for='thickness']{
    //flex: 0 0 10rem;
  }
  label[for='quantity']{
    input{
      flex: 0 0 230px;
    }
  }
}