@import "../variables";

a {
  color: $link-color;
  text-decoration: none;
  outline: 0;
  &.brand{
    color: $link-color-brand;
  }
}


@media screen and (min-width: 768px){
  a{
    &:hover,
    &:focus {
      color: $link-color-hover;
    }
  }
}