@import '1-tools/colours';



//        Description of design techniques:
//   Html font size has been set at 65.5% of browser's default size(which is 16), thus making 1rem = 10px;
//   Rem units used for declaring font size, padding , margin;
//   For border and letter spacing, the pixel size(px) unit was kept;



// Font variables
$fontMain: 'Lora', serif;
$fontSecondary: 'Open Sans', sans-serif;
$baseFontSize: 1.5rem;
$baseLineHeight: 1.4;
$baseFontColor: #4c4c4c;

///// General settings
$body-background-color: #fff;

// Link colors
$link-color: gray;
$link-color-hover: darken($link-color, 20%);

$link-color-brand: black;
$link-color-brand-hover: lighten($link-color-brand, 20%);



//Custom variables

$pre-color: darken($silver, 5%);
$button-color: #555;
$button-background-color: transparent;
$button-border-color: #bbb;

$button-color-hover: #333;
$button-border-color-hover: #888;



$selection-color: #FFF498;
$img-selection-background-color: transparent;

$heading-color: #222;

$line-color: #ddd;

$fork-tag-color: white;
$fork-tag-background-color: blue;
$fork-tag-color-hover: white;
$section-background-color: rgba(255,255,255,0.7);
$section-shadow-color: rgba(0,0,0,0.1);

