
.btn{
  display: inline-block;
  border-radius: 1px;
  border: none;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0.2s;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background-color: $colorBrand;
  color: white;
  margin: 1rem 0;
  font-family: $fontSecondary;
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 1.2rem 1.5rem;
  outline: none;
  cursor: pointer;
  &:active, &:hover{
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    transition-delay: 0s;
    background-color: lighten($colorBrand, 40);
    color: $colorBrand;
    outline: 1px solid $colorBrand;
  }
}