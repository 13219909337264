





.is-active{
  display:block;
}

.is-active-flex{
  display:flex;
}

.is-visible{
  visibility: visible;
}
.is-invisible{
  visibility: hidden;
}

.pull-right{
  float: right;
}

//

.image{
  display: block;
  height: 0;
  padding-top: 60%;
  margin: 3% 12%;
  background: no-repeat center;
  background-size: contain;
}

.bg-grey{
  background-color: $silverlight;
}
.bg-pattern{
  padding: 1.5rem;
  background: $colorBrand url(/res/images/site/pattern-transparent.png);
  color: white;
  h1,h2{
    color: white;
  }
  .separator{
    border-top: 1px solid white;
    .icon{
      background: $colorBrand url(/res/images/site/pattern-transparent.png);
      color: white;
    }
  }
}

.text-center{
  text-align: center;
}

.text-big{
  font-size: 19px;
}.text-bigger{
  font-size: 24px;
   font-weight: 300;
}
 .text-large{
   font-size: 25px;
   font-weight: 300;
 }

.text-brand{
  color: $colorBrand;
}

@media screen and (min-width: 680px) {
  .text-large{
    font-size: 33px;
  }
}

@media screen and (min-width: 768px) {
  .bg-pattern{
    padding: 4rem;
  }
}