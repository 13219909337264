.nav-main{
  display:none;
  background-color: $silverlight;
  width: 100%;
  margin-top: 1rem;

  &.is-open{
    display:block;
  }
  ul{
    text-align: left;
  }
  li{
    a{
      display:block;
      padding:10px;
      border-bottom: 1px solid darken($silverlight,5);
      font-family: $fontSecondary;
      font-weight: 500;
      text-align: left;

      &.active{
        color:black;
      }
    }
  }
}

.toggleNav{
  position: absolute;
  left: 1rem;
  top: 1rem;
  cursor:pointer;
  span{
    display: none;
    font-size: 2.9rem;
    line-height: 55px;
    color: $colorBrand;
    &.is-active{
      display: block;
    }
  }
  //Toggle the main navigation - show it if toggleNav btn has .is-open class
  &.is-open ~ .nav-main{
    display: block;

  }
}

@media screen and (min-width: 680px){
  .toggleNav{
    display: none;
  }

  .nav-main, .nav-main.is-open{
    order:1;
    flex: 1 0 100%;
    //height: 55px;
    //line-height: 55px;
    background-color: transparent;
    text-align: left;
    display: block;

    ul{

    }
    li {
      display: inline-block;
      a{
        display: inline-block;
        border: none !important;
        font-size: 16px;

        &:hover{
          color: $colorBrand;
          cursor: pointer;
        }
      }
    }
  }
}



@media screen and (min-width: 875px){
  .toggleNav{
    display: none;
  }

  .nav-main, .nav-main.is-open{
    order:1;
    //height: 55px;
    //line-height: 55px;
    background-color: transparent;
    text-align: left;
    display: block;


    li {
      display: inline-block;

    }

    li a{
      display: inline-block;
      border: none !important;
      //font-size: 16px;
    }
  }
}

@media screen and (min-width: 1024px){
  .nav-main, .nav-main.is-open{
    position: absolute;
    bottom: 0;
    right: 0;
    ul{
      text-align: right;
    }
    li {
      a{
        //font-size: 18px;
      }
    }
  }

}