

.img-slider{
  flex: 0 0 100%;
}

.services {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: space-around;
  //padding: 2rem 0;
  &__block {
    flex: 0 0 45%;
    //border: 1px solid darkolivegreen;
    text-align: center;
    border-bottom: 1px solid $colorBrand;
    margin-bottom: 1.5rem;
  }
  &__icon{
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-45deg);
    background-color: $colorBrand;

    width: 8rem;
    height: 8rem;
    margin: 2rem auto;
    .icon{
      color: white;
      transform: rotate(45deg);
    }
  }
  &__content{
    h2, .h2{
      font-size: 1.7rem;
    }
    p{
      font-size: 1.3rem;
    }
  }
}
.separator{
  width: 30rem;
  margin: 0 auto;
  border-top: 1px solid $colorBrand;
  text-align: center;
  &__simple{
    width: 8rem;
    border-top: 1px solid $colorBrand;
    margin : 1rem 0;
    .feature > &{
      margin-left: 2rem;
    }
  }
  .icon{
    position: relative;
    font-size: 3rem;
    top: -15px;
    background-color: white;
    padding: 0 2rem;
  }
}
.dual-content{
  width: 100%;
  margin: 4rem 0 4rem auto;
  padding: 2rem;
  p{
    font-weight: 300;
  }
  &.left{
    margin: 4rem auto 4rem 0;
  }
}

.tile{
  &-group{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 100%;
  }
  position: relative;
  flex: 0 0 100%;
  padding: 2rem;
  margin: 1rem 0;
  ul{
    list-style: none;
  }
  li{
    padding: 0.5rem 0;
  }
  h3{
    color: $colorBrand;
    font-size: 2rem;
    margin: 1rem;
  }
  .icon, em{
    position: absolute;
    right: 2rem;
    bottom: 2rem;
    //width: 12rem;
    //height: 12rem;
    font-size: 12rem;
    color: rgba(0,0,0,0.1)
  }
}
.opinion{
  position: relative;
  font-style: italic;
  font-size: 1.7rem;
  text-align: center;
  padding: 1rem;
  margin: 0;
  span{
    position: relative;
    top: 1rem;
    display: block;
    font-style: normal;
    font-weight: bold;
  }

}
.feature{
  flex: 0 0 100%;
  border-bottom: 1px solid $colorBrand;
  margin-bottom: 2rem;
  &__img{
    //border: 1px solid yellow;
    padding-top: 70%;
    background: no-repeat center;
    background-size: cover;
  }
  &__icon{
    display: flex;
    //padding-top: 70%;
    align-items: center;
    justify-content: center;
    background: darken($colorBrand,10) url("/res/images/site/pattern-transparent.png")  center;
    .services__icon{
      margin: 3em auto;
    }
  }
  &__title{
    margin-top: 1rem;
    color: $colorBrand;
    font-size: 2rem;
    font-weight: 300;
    padding: 0 2rem;
  }
  p{
    text-align: justify;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.hero-image{
  width: 100%;
  padding-bottom: 40%;
  background: url("/res/images/site/slider/slider-img1.png") no-repeat center;
  background-size: cover;
}


@media screen and (min-width: 460px){
  .feature{
    flex: 0 0 46%;
    border-bottom: none;
    margin-bottom: 0;
    &__title, p{
      padding:0;
    }
  }
  .separator{
    &__simple{
      .feature > &{
        margin-left: 0;
      }
    }
  }
}


@media screen and (min-width: 680px){
  .dual-content{
    width: 50%;
  }
  .feature{
    &__title, p{
      padding:0;
    }
    &__title{
      font-size: 2.4rem;
    }
  }
  .tile{
    flex: 0 0 49%;
  }

}


@media screen and (min-width: 768px) {
  .opinion{

  }

  .services{
    margin: 4rem 0;
    &__block{
      flex: 0 0 20%;
      border-bottom: none;
      margin-bottom: 0;
    }
  }

  .feature.feature--big {
    flex: 0 0 46%;
  }

  .partner-sites {
    a {
      flex: 25%;
    }
  }
}
@media screen and (min-width: 1024px){

  .feature{
    flex: 0 0 23%;
  }

}