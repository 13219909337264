footer {

  background-color: $color2;
  padding: 1rem;

  .footer-list {
    display: flex;
    margin: 1rem 0;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;

    font-family: $fontSecondary;
    font-weight: bold;
    line-height: 1.15;

    &__title {
      display: inline-block;
      margin-bottom: 1.5rem;
      color: black;
      text-transform: uppercase;
    }

  }
  .footer-logo {
    text-align: center;
    img {
      max-width: 14rem;
      height: auto;
    }
  }
}

.footer-nav {
  flex: 100%;
  a {
    display: block;
    border-radius: 2px;
    padding: 1rem 0;
    margin: 0.5rem 0;
    &:hover {
      border-color: $color3;
    }
  }
}

//Helper

@media screen and (min-width: 768px) {

  .footer-nav{
    flex: 500px;
    display: flex;
    justify-content: space-around;
    i{
      display: none;
    }
  }

}
@media screen and (min-width: 1280px) {

  .footer-nav{
    a{
      padding: 1rem;
      i{
        display: none;
      }
    }
  }
}