@import "../variables";

//	Headings



.gigantic, .huge, .large, .bigger, .big,
h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;
  font-family: $fontSecondary;
  font-weight: bold;
  color: $colorBrand;

}

.gigantic {
  font-size: 11rem;
  line-height: 1.09;
  letter-spacing: -2px;
}

.huge, h1 {
  font-size: 2.6rem;
  line-height: 1.05;
  .gallery-content & {
    font-size: 4rem;
  }
}

.large, h2 {
  font-size: 3.1rem;
  line-height: 1.14;
  color: $colorBrand;
}

.bigger, h3 {
  font-size: 2.7rem;
  line-height: 1.38;

}

.big, h4 {
  font-size: 2.7rem;
  line-height: 1.38;
  color: $grayash;
}

.small, small {
  font-size: 1rem;
  line-height: 1.2;
}



// Custom Basic Text Style///

p {
  margin: 0 0 2rem 0;
}
h1+p{
  font-family: "Open Sans Condensed",Arial, sans-serif;
  font-weight: bold;
}
em {
  font-style: italic;
}
strong {
  font-weight: bold;
}
hr {
  border: solid $line-color;
  border-width: 1px 0 0;
  clear: both;
  margin: 1rem 0 3rem;
  height: 0;
}

ul{
  list-style: none;
  padding: 0;
  margin: 0;
}

#description{

}

@media screen and(min-width: 680px){
  .h1, h1, .huge{
    font-size: 5.5rem;
  }
}